import * as SentrySvelte from '@sentry/svelte';
import type { HandleClientError } from '@sveltejs/kit';
import { PUBLIC_SENTRY_DSN } from '$env/static/public';
import { OpenAPI } from './services/api-client';
import { constants } from './constants';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

SentrySvelte.init({
	dsn: PUBLIC_SENTRY_DSN,
	// integrations: [new SentrySvelte.BrowserTracing()],
	tracesSampleRate: 0.1
});

SentrySvelte.setTag('svelteKit', 'browser');

OpenAPI.BASE = constants.baseApiUrl;

dayjs.extend(utc);

// This will catch errors in load functions from +page.ts files
export const handleError = (({ error, event }) => {
	SentrySvelte.captureException(error, { contexts: { sveltekit: { event } } });

	var message = 'unknown error';
	if ((error as any)?.message) message = (error as any).message;

	return {
		message: message
	};
}) satisfies HandleClientError;
